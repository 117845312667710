<script>
import VAbsenceDetails from "@/views/Reports/components/VAbsenceDetails";
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import DateRangeMixin from "@/mixins/DateRangeMixin";
import TaskService from "@/services/TaskService";
import DepartmentService from "@/services/DepartmentService";
import UserService from "@/services/UserService";

export default {
    name: "VHours",
    components: { DateRangePicker, VAbsenceDetails },
    mixins: [DateRangeMixin],
    data() {
        return {
            loading: false,
            stats: null,
            users: [],
            deps: [],
            blockUser: false,
            showDetailsModal: false,
            detailsConditions: {},
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        currentExportUrl: function () {
            let params = this.$buildParams();
            delete params.sort;
            delete params.direction;
            delete params.page;

            let url = new URL(
                location.protocol + "//" + location.host + "/v1/tasks/absence"
            );
            url.searchParams.append("csv", 1);

            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        // PHP way
                        params[param].forEach((v, idx) => {
                            url.searchParams.append(`${param}[${idx}]`, v);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        },
    },
    watch: {},
    async created() {
        this.loading = true;
        this.$aclGuard(this.$ACL.STATS_VIEW);
        this.resetRange(true);
        await this.fetchUsers(true);
        await this.fetchDepartments(true);
        await this.fetchData();
        this.$apiEnableWatcher();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;

                if (this.apiSearchFilter.department_id != "") {
                    this.apiSearchFilter.user_id = "";
                    this.blockUser = true;
                } else {
                    this.blockUser = false;
                }

                let params = this.$buildParams();
                const r = await TaskService.GET_absence(params);
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData e", e);
            }

            this.loading = false;
        },
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        async fetchUsers(loading = false) {
            try {
                this.loading = true;
                const r = await UserService.dropdownAll();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers e", e);
            }
            this.loading = loading;
        },
        resetRange(set_default = false) {
            let from = null;
            let to = null;

            if (set_default) {
                from = dayjs().startOf("month").format("YYYY-MM-DD");
                to = dayjs().endOf("month").format("YYYY-MM-DD");
            }

            this.apiSearchFilter = {
                dateRange: {
                    startDate: from,
                    endDate: to,
                },
                user_id: "",
                department_id: "",
            };
        },
        showDetails({ user_id, activity_id = null } = {}) {
            this.detailsConditions = {
                user_id,
                activity_id,
                dateRange: this.serializeDateRange(
                    this.apiSearchFilter.dateRange
                ),
            };
            this.showDetailsModal = true;
        },
    },
};
</script>

<template>
    <div class="v-reports-activities">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <!-- .top-menu -->
            <div class="row card-wrapper" v-if="true">
                <div class="col-12">
                    <div class="d-flex align-items-center flex-row mb-3">
                        <div class="v-stats-rp">
                            <date-range-picker
                                class="range-picker"
                                ref="picker"
                                opens="right"
                                :timePicker="false"
                                :showWeekNumbers="true"
                                :autoApply="true"
                                v-model="apiSearchFilter.dateRange"
                                :linkedCalendars="true"
                                :locale-data="getDateRangePickerLocale()"
                                :ranges="getDateRangePickerRanges()"
                            >
                                <template #header>
                                    <manual-range
                                        :date-range-manual="dateRangeManual"
                                        :date-range-error="dateRangeError"
                                        @exec="
                                            execManualRange(
                                                'apiSearchFilter',
                                                'dateRange'
                                            )
                                        "
                                    />
                                </template>

                                <template
                                    v-slot:input="picker"
                                    style="min-width: 350px"
                                >
                                    <template v-if="picker.startDate">
                                        {{ $d(picker.startDate, "short") }}
                                        -
                                        {{ $d(picker.endDate, "short") }}
                                    </template>
                                </template>
                            </date-range-picker>
                        </div>

                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="deps"
                                v-model="apiSearchFilter.department_id"
                                :placeholder="
                                    $t('stats.placeholder_select_department')
                                "
                            />
                        </div>

                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="users"
                                v-model="apiSearchFilter.user_id"
                                :placeholder="
                                    $t('stats.placeholder_select_user')
                                "
                                :disabled="blockUser"
                            />
                        </div>
                        <div
                            class="text-muted small m-click m-link ml-3"
                            @click="resetRange"
                        >
                            <i class="far fa-sync"></i>
                            {{ $t("stats.reset") }}
                        </div>
                        <a
                            class="ml-auto btn btn-primary"
                            :href="currentExportUrl"
                            >{{ $t("stats.download_csv") }}</a
                        >
                    </div>
                </div>
                <div class="col-12" v-if="loading">
                    <v-loader :loading="true" />
                </div>

                <div class="col-12" v-else-if="false">
                    <div class="alert alert-warning alert-outline">
                        {{ $t("inv_stats.no_data_found_try_different_period") }}
                    </div>
                </div>
            </div>
            <!-- New stat row -->

            <!-- New stat row -->
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <div
                            class="table-responsive"
                            v-if="Object.keys(stats.stats).length > 0"
                        >
                            <v-table class="table table-mg table-sm">
                                <v-thead>
                                    <v-tr>
                                        <th>{{ $t("absence.name") }}</th>
                                        <th
                                            class="text-right"
                                            v-for="(
                                                name, a_id
                                            ) in stats.activities"
                                            :key="a_id"
                                        >
                                            {{ name }}
                                        </th>
                                        <th class="text-right">
                                            {{ $t("absence.total") }}
                                        </th>
                                    </v-tr>
                                </v-thead>
                                <v-tr
                                    v-for="(s, index) in stats.stats"
                                    :key="index"
                                >
                                    <td>{{ s.user }}</td>

                                    <td
                                        class="text-right"
                                        :class="
                                            s['data'][a_id] == 0
                                                ? 'text-muted'
                                                : ''
                                        "
                                        v-for="(name, a_id) in stats.activities"
                                        :key="a_id"
                                    >
                                        <template v-if="s['data'][a_id] == 0"
                                            >---</template
                                        >
                                        <span
                                            v-else
                                            @click="
                                                showDetails({
                                                    user_id: s.user_id,
                                                    activity_id: a_id,
                                                })
                                            "
                                            class="m-click"
                                            ><i
                                                class="fa fa-search fa-supermini"
                                            ></i>
                                            {{ s["data"][a_id] | toTime }}</span
                                        >
                                    </td>
                                    <td class="text-right">
                                        <span
                                            class="m-click"
                                            @click="
                                                showDetails({
                                                    user_id: s.user_id,
                                                })
                                            "
                                        >
                                            <i
                                                class="fa fa-search fa-supermini"
                                            ></i>
                                            {{ s.total | toTime }}
                                        </span>
                                    </td>
                                </v-tr>
                                <v-tbody> </v-tbody>
                            </v-table>
                        </div>
                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("absence.no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <!-- .container-fluid -->
        <portal to="modals">
            <v-absence-details
                v-if="showDetailsModal"
                @close="showDetailsModal = false"
                :conditions="detailsConditions"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-stats-rp {
    flex: 0 0 300px;
}

.fa-supermini {
    font-size: 12px !important;
    color: $gray-600;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}
</style>
