<script>
import TaskService from "@/services/TaskService";

export default {
    name: "VAbsenceDetails",
    props: {
        conditions: {},
    },
    data() {
        return {
            loading: true,
            tasks: [],
        };
    },
    created() {
        this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await TaskService.GET_absenceDetails(
                    this.conditions
                );

                this.tasks = data.data;
            } catch (e) {}
            this.loading = false;
        },
    },
};
</script>

<template>
    <modal
        size="xl"
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">{{
            $t("absence.title_modal_absence_details")
        }}</template>
        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <template v-else-if="tasks">
                <v-table class="table table-sm table-striped">
                    <v-thead>
                        <v-tr>
                            <v-th>{{$t('absence.details_tbl_hdr_date')}}</v-th>
                            <v-th>{{$t('absence.details_tbl_hdr_type')}}</v-th>
                            <v-th>{{$t('absence.details_tbl_hdr_user')}}</v-th>
                            <v-th class="text-right">{{$t('absence.details_tbl_hdr_time')}}</v-th>
                        </v-tr>
                    </v-thead>

                    <v-tbody>
                        <v-tr v-for="task in tasks.tasks" :key="task.id">
                            <v-td>{{
                                $d(new Date(task.task_date), "short")
                            }}</v-td>
                            <v-td>{{ task.activity.name }}</v-td>
                            <v-td>{{ task.user.name }}</v-td>
                            <v-td class="text-right">{{
                                task.time_reg | toTime
                            }}</v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </template>
        </template>
    </modal>
</template>


<style>
</style>
